import { VerticalSpace } from '../../../components/space/vertical-space'
import { DIPersonDataForm } from '../components/person-data-form'
import { DIPersonContactForm } from '../components/person-contact-form'

import { formItemActualAddress } from '../constants'
import { DIPersonAddress } from '../components/person-address-form'
import { Form } from 'antd'

export const FirstStep = () => {
  return (
    <VerticalSpace>
      <Form.Item name={['personMainData', 'registrationAddress']} hidden />
      <DIPersonDataForm disabled={false} />
      <DIPersonContactForm disabled={false} />
      <DIPersonAddress
        formItemAddress={formItemActualAddress}
        isActualAddress={true}
        viewMode={false}
        title="Փաստացի բնակության հասցե"
        doAddressesMatch={false}
      />
    </VerticalSpace>
  )
}
