import { Form, Radio } from 'antd'
import {
  disabledDepList,
  familyData,
  memberDetails,
  minorDepList,
  subItemStyle,
} from '../constants'
import { InputNumber } from '../../../components/input-number'
import { useEffect } from 'react'

export const FamilyDetails = () => {
  const formData = Form.useWatch([familyData, memberDetails], {
    preserve: true,
  })
  const form = Form.useFormInstance()

  useEffect(() => {
    const calculateAutofills = () => {
      form.setFieldsValue({
        [familyData]: {
          [memberDetails]: {
            ...formData,
            minor: minorDepList.reduce(
              (sum, key) => sum + (formData?.[key] || 0),
              0,
            ),
            disabled: disabledDepList.reduce(
              (sum, key) => sum + (formData?.[key] || 0),
              0,
            ),
          },
        },
      })
    }

    calculateAutofills()
  }, [formData, form])
  return (
    <>
      <Form.Item
        name={[familyData, memberDetails, 'adults']}
        label="Ընտանիքում բոլոր չափահաս անդամների թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'women']}
        label="Որից՝ կին"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'minor']}
        label="Ընտանիքում բոլոր անչափահաս անդամների թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} disabled />
      </Form.Item>
      <p {...subItemStyle}>Որից՝</p>
      <Form.Item
        name={[familyData, memberDetails, 'underSixMonths']}
        label="Ընտանիքում մինչև 6 ամսական երեխաների թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'fromSixMonthToOneYear']}
        label="Ընտանիքում 6 ամսականից մինչև 1 տարեկան երեխաների թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'fromOneToThreeYears']}
        label="Ընտանիքում 1 տարեկանից մինչև 3 տարեկան երեխաների թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'fromThreeToSixYears']}
        label="Ընտանիքում 3 տարեկանից մինչև 6 տարեկան երեխաների թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'pupils']}
        label="Դպրոցահասակ երեխաների թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      {/*//!  */}
      <Form.Item
        name={[familyData, memberDetails, 'disabled']}
        label="Ընտանիքում հաշմանդամություն կամ ֆունկցիոնալության սահմանափակումներ ունեցող անձանց թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} disabled />
      </Form.Item>
      <p {...subItemStyle}>Որից՝</p>
      <Form.Item
        name={[familyData, memberDetails, 'immovable']}
        label="Հենաշարժական խնդիրներով հաշմանդամություն ունեցող անձանց թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'psychic']}
        label="Մտավոր կամ հոգեկան առողջության խնդիրներ ունեցող անձանց թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'ocular']}
        label="Տեսողական խնդիրներով հաշմանդամություն ունեցող անձանց թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'hearing']}
        label="Լսողական խնդիրներով հաշմանդամություն ունեցող անձանց թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'chronic']}
        label="Քրոնիկական հիվանդություններով հաշմանդամություն ունեցող անձանց թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <p {...subItemStyle}>Որից՝</p>
      <Form.Item
        name={[familyData, memberDetails, 'disabledChildren']}
        label="Հաշմանդամություն ունեցող կամ ֆունկցիոնալության սահմանափակումներ ունեցող երեխաների թիվը"
        rules={[{ required: true }]}
        {...subItemStyle}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'membersNeedAdjustments']}
        label="Անվասայլակի, պրոթեզի օգտագործման, տեսողական կամ այլ խնդիրներով պայմանավորված՝ կացարանի հարմարեցման կարիք ունեցող անձանց թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'overSixtyfive']}
        label="Ընտանիքում 65 տարեկան և բարձր անձանց թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'peopleNeedCare']}
        label="Քրոնիկ առողջական խնդիրների պատճառով խնամքի կարիք
        ունեցող անձանց թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        name={[familyData, memberDetails, 'pregnant']}
        label="Ընտանիքում հղի կանանց թիվը"
        rules={[{ required: true }]}
      >
        <InputNumber style={{ width: '100%' }} min={0} />
      </Form.Item>
      <Form.Item
        label="Արդյո՞ք ճգնաժամի արդյունքում ընտանիքի անդամներից որևէ մեկի գտնվելու տեղն անհայտ է"
        name={[familyData, 'hasLostMember']}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  )
}
