import { Form } from 'antd'
import { Checkbox } from '../../../../components/checkbox'
import { careOptions } from '../../constants'

export const CareList = ({ parentFormItem }) => {
  const validateAtLeastOneChecked = (_, value) => {
    const isAnyChecked = value && Object.values(value).some((v) => v === true)
    if (!isAnyChecked) {
      return Promise.reject(
        new Error('Խնդրում ենք ընտրել բացված դաշտերից առնվազն 1-ը'),
      )
    }
    return Promise.resolve()
  }

  return (
    <Form.Item
      name={parentFormItem}
      rules={[{ validator: validateAtLeastOneChecked }]}
      style={{ marginLeft: '20px' }}
    >
      {careOptions.map((option) => (
        <Form.Item
          key={option.id}
          name={[...parentFormItem, option.fieldName]}
          valuePropName="checked"
          style={{ margin: 0 }}
        >
          <Checkbox value={option.id}>{option.label}</Checkbox>
        </Form.Item>
      ))}
    </Form.Item>
  )
}
