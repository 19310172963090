import {
  Button,
  Card,
  Col,
  Grid,
  Layout,
  List,
  Modal,
  Row,
  Tooltip,
} from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { GerbSection } from '../../components/form/layouts/gerb-logo'
import { VerticalSpace } from '../../components/space/vertical-space'
import { PATHS } from '../../helpers/constants'
import { ReactComponent as GerbMobile } from './gerb-color.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as Nork } from './nork.svg'

const { Footer, Header } = Layout
const { useBreakpoint } = Grid

const CustomCard = styled(Card)`
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 50px;
  width: calc(100vw - 100px); // Full width minus 2*50px margin
  height: calc(100vh - 100px); // Full height minus 2*50px margin
  max-height: 920px;
  margin: 50px;

  /* justify-content: center;
  align-items: center; */
  .ant-card-body {
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
  }
`

const ImageSection = styled.div`
  flex: 1; // Takes equal space as content section
  overflow: hidden; // Prevents overflow of image
  width: 100%;
  height: 101%;
  position: relative;
`

const FullHeightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: inherit; // Ensures the image covers the full section
  display: block; // Removes default inline styling on images
  position: absolute;
  z-index: 0;
`

const CityImage = styled.img`
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 32%;
  width: 400px;
  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 200px;
    top: 13%;
    left: 40%;
  }
  @media (min-width: 1200px) and (max-width: 1400.98px) {
    width: 250px;
    top: 13%;
    left: 35%;
  }
`

const HouseImage = styled.img`
  position: absolute;
  z-index: 1;
  top: 34%;
  left: 21%;
  width: 400px;
  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 200px;
    top: 36%;
    left: 30%;
  }
  @media (min-width: 1200px) and (max-width: 1400.98px) {
    width: 250px;
    top: 39%;
    left: 22%;
  }
`
const Palmmage = styled.img`
  position: absolute;
  z-index: 1;
  top: 55%;
  left: 37%;
  width: 400px;
  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 200px;
    top: 55%;
    left: 44%;
  }
  @media (min-width: 1200px) and (max-width: 1400.98px) {
    width: 250px;
    top: 59%;
    left: 36%;
  }
`

const ContentSection = styled.div`
  flex: 1; // Takes equal space as image section
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const data = [
  {
    title: 'Ֆիզիկական անձ կամ անձանց խումբ կամ նախաձեռնություն',
    onClick: (modal) =>
      modal.confirm({
        title: 'ՈՒշադրություն',
        onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
        content:
          'Եթե ֆիզիկական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ  լրացրեք ՀՀ ԱՍՀՆ-ի  հետ համագործակցության հայտը: ',
        okText: 'Դիմել',
        cancelText: 'Փակել',
        closable: true,
        cancelButtonProps: { style: { display: 'none' } },
      }),
  },
  {
    title: 'Իրավաբանական անձ',
    onClick: (modal) =>
      modal.confirm({
        title: 'ՈՒշադրություն',
        onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
        content:
          'Եթե իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (ԱՍՀՆ) հետ ունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Եթե չունեք, ապա կարող եք լրացնել համագործակցության հայտ, որից հետո կկարողանաք ներկայացնել աջակցության հայտը: ',
        okText: 'Դիմել',
        cancelText: 'Փակել',
        closable: true,
        cancelButtonProps: { style: { display: 'none' } },
      }),
  },
  {
    title: 'Օտարերկրյա անձ  կամ անձանց խումբ կամ նախաձեռնություն',
    onClick: (modal) =>
      modal.confirm({
        title: 'ՈՒշադրություն',
        onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
        content:
          'Եթե իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (ԱՍՀՆ) հետ ունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Եթե չունեք, ապա կարող եք լրացնել համագործակցության հայտ, որից հետո կկարողանաք ներկայացնել աջակցության հայտը: ',
        okText: 'Դիմել',
        cancelText: 'Փակել',
        closable: true,
        cancelButtonProps: { style: { display: 'none' } },
      }),
  },
  {
    title: 'Օտարերկրյա կազմակերպություն կամ միջազգային կազմակերպություն',
    onClick: (modal) =>
      modal.confirm({
        title: 'ՈՒշադրություն',
        onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
        content:
          'Եթե իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (ԱՍՀՆ) հետ ունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Եթե չունեք, ապա կարող եք լրացնել համագործակցության հայտ, որից հետո կկարողանաք ներկայացնել աջակցության հայտը: ',
        okText: 'Դիմել',
        cancelText: 'Փակել',
        closable: true,
        cancelButtonProps: { style: { display: 'none' } },
      }),
  },
]

const MobileHeader = styled(Header)`
  height: 68px;
  box-shadow: 4px 4px 6px 0px #00000026;
  display: flex;
  justify-content: start;
  padding: 5px;
  align-items: center;
  background-color: transparent;
`

export const Title = () => {
  const screens = useBreakpoint()
  return (
    <h1
      style={{
        color: '#042C68',
        fontSize: screens.xxl ? '35px' : screens.lg ? '25px' : '15px',
        fontWeight: '700',
        textTransform: 'uppercase',
        textAlign: 'center',
      }}
    >
      հրատապ արձագանքման հարթակ
    </h1>
  )
}

const ContentData = ({ modal, screen }) => {
  const navigate = useNavigate()
  return (
    <ContentSection>
      {screen.lg && <Title />}
      <VerticalSpace size={60}>
        <VerticalSpace size="middle">
          <span
            style={{
              fontSize: screen.xxl ? '21px' : screen.lg ? '18px' : '15px',
              fontWeight: 700,
            }}
          >
            Այս հարթակը նախատեսված է հրատապ արձագանք պահանջող իրավիճակներում
            առաջնային կարիքներին արձագանքելու համար։
          </span>
          <span
            style={{
              fontSize: screen.xxl ? '21px' : screen.lg ? '18px' : '15px',
              fontWeight: 700,
            }}
          >
            Եթե վերջին 72 ժամվա ընթացքում տուժել եք այնպիսի հանկարծահաս
            ճգնաժամերից, ինչպիսիք են՝ բնական, կլիմայական, տեխնոլոգիական
            աղետները, ռազմական գործողությունները կամ կյանքին, առողջությանը վտանգ
            սպառնացող այլ իրավիճակներ, և ունեք հրատապ արձագանքի կարիք Ձեր
            առաջնային կարիքները բավարարելու համար, ապա կարող եք օգտվել այս
            հարթակից
          </span>
        </VerticalSpace>
        <Row gutter={screen.lg ? 10 : [10, 30]}>
          <Col span={screen.lg ? 12 : 24}>
            <Button
              block
              type="primary"
              size="large"
              style={{
                backgroundColor: '#497AC2',
                textTransform: 'uppercase',
                height: '75px',
                padding: '20px 45px',
                position: 'relative',
              }}
              shape="round"
              onClick={() => {
                modal.confirm({
                  title: 'ՈՒշադրություն',
                  onOk: () => navigate(PATHS.RAPID_NEED),
                  content:
                    'Հանկարծահաս ճգնաժամի հետևանքով վերջին 72 ժամվա ընթացքում ի հայտ եկած Ձեր  առաջնային կարիքները հաշվառելու համար կարող եք Դիմումների կառավարման միասնական հարթակում ստեղծել օգտահաշիվ կամ լրացնել դիմումն առանց օգտահաշիվ բացելու։ Դիմումում Ձեր նշած հեռախոսահամարով Ձեզ հետ կապ կհաստատի սոցիալական աշխատողը՝ անհրաժեշտ հարցերի պարզաբանման համար։',
                  okText: 'Դիմել',
                  cancelText: 'Փակել',
                  closable: true,
                  cancelButtonProps: { style: { display: 'none' } },
                })
              }}
            >
              Կարիք ունեմ
              <Tooltip title="Հանկարծահաս ճգնաժամի հետևանքով ի հայտ եկած Ձեր կամ Ձեր ընտանիքի առաջնային կարիքները հաշվառելու համար սեղմեք «Առաջնային կարիք ունեմ» կոճակը եւ լրացրեք հայտը: Կարևոր է հայտում լրացնել կոնտակտային ճշգրիտ տվյալներ: Ձեր թողած հեռախոսահամարով, ըստ անհրաժեշտության, Ձեզ հետ կապ կհաստատի սոցիալական աշխատողը։">
                <Info
                  style={{ position: 'absolute', right: '15', top: '10' }}
                />
              </Tooltip>
            </Button>
          </Col>
          <Col span={screen.lg ? 12 : 24}>
            <Button
              block
              type="primary"
              size="large"
              style={{
                backgroundColor: '#042C68',
                textTransform: 'uppercase',
                height: '75px',
                padding: '20px 45px',
                position: 'relative',
              }}
              shape="round"
              onClick={() => {
                modal.confirm({
                  title: 'ՈՒշադրություն',
                  width: screen.lg ? '40%' : '90%',
                  onOk: () => navigate(PATHS.RAPID_NEED),
                  content: screen.lg ? (
                    <List
                      grid={{
                        xs: 1,
                      }}
                      header="Աջակցության Ձեր առաջարկը հայտագրելու համար խնդրում ենք
                ստեղծել օգտահաշիվ եւ լրացնել հայտը: Խնդրում ենք նշված
                տարբերակներից ընտրել ձեզ համապատասխանողը․"
                      // dataSource={data}
                      itemLayout="vertical"
                    >
                      <VerticalSpace>
                        {data.map((item) => (
                          <List.Item.Meta
                            key={item.title}
                            style={{ textAlign: 'left' }}
                            description={
                              <Button
                                type="link"
                                onClick={() => item.onClick(modal)}
                                block
                              >
                                {item.title}
                              </Button>
                            }
                          />
                        ))}
                      </VerticalSpace>
                    </List>
                  ) : (
                    <VerticalSpace>
                      <span>
                        Աջակցության Ձեր առաջարկը հայտագրելու համար խնդրում ենք
                        ստեղծել օգտահաշիվ եւ լրացնել հայտը: Խնդրում ենք նշված
                        տարբերակներից ընտրել ձեզ համապատասխանողը․
                      </span>
                      <ul style={{ listStyle: 'none' }}>
                        {data.map((item) => (
                          <li key={item.title}>
                            <Button
                              type="link"
                              onClick={() => item.onClick(modal)}
                              style={{ textAlign: 'left', textWrap: 'wrap' }}
                            >
                              {item.title}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </VerticalSpace>
                  ),
                  okText: 'Դիմել',
                  cancelText: 'Փակել',
                  closable: true,
                  cancelButtonProps: { style: { display: 'none' } },
                  okButtonProps: { style: { display: 'none' } },
                })
              }}
            >
              աջակցում եմ
              <Tooltip title="Եթե ցանկանում եք աջակցել  հանկարծահաս ճգնաժամի հետևանքով առաջնային կարիքներ ունեցող անձանց կամ ընտանիքներին, ապա սեղմեք «Աջակցում եմ» կոճակը եւ լրացրեք հայտը։ Հայտը կարող են լրացնել թե՛ իրավաբանական, թե՛ ֆիզիկական անձինք։">
                <Info
                  style={{ position: 'absolute', right: '15', top: '10' }}
                />
              </Tooltip>
            </Button>
          </Col>
        </Row>
        {/* <Row justify="center">
          <Col span={screen.lg ? 8 : 24}>
            <Button
              block
              type="primary"
              size="large"
              style={{
                backgroundColor: '#708DBA',
                textTransform: 'uppercase',
                height: '75px',
                padding: '20px 45px',
                position: 'relative',
              }}
              shape="round"
              onClick={() => navigate(PATHS.MAPS)}
            >
              Քարտեզ
            </Button>
          </Col>
        </Row> */}
      </VerticalSpace>
    </ContentSection>
  )
}

const NewHomePage = () => {
  const [modal, contextHolder] = Modal.useModal()
  const screens = useBreakpoint()

  return (
    <div>
      {screens.lg ? (
        <CustomCard>
          <ImageSection>
            <div
              style={{
                position: 'absolute',
                top: '20px',
                left: '30px',
                zIndex: '1',
              }}
            >
              <GerbSection />
            </div>
            <FullHeightImage src="/images/bg.png" alt="Description of image" />
            <CityImage src="/images/city.png" alt="Description of image" />
            <HouseImage src="/images/house.png" alt="Description of image" />
            <Palmmage src="/images/palm.png" alt="Description of image" />
            <Nork
              style={{
                position: 'absolute',
                bottom: '-30',
                left: '0',
                zIndex: '1',
              }}
            />
          </ImageSection>

          <ContentData modal={modal} screen={screens} />
        </CustomCard>
      ) : (
        <Layout>
          <MobileHeader>
            <GerbMobile />
          </MobileHeader>
          <VerticalSpace>
            <Title />
            <ContentData modal={modal} screen={screens} />
          </VerticalSpace>
          <Footer style={{ display: 'flex', justifyContent: 'center' }}>
            <Nork />
          </Footer>
        </Layout>
      )}
      {contextHolder}
    </div>
  )
}

export default NewHomePage
