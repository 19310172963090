import { useQueries } from '@tanstack/react-query'
import {
  URL_BUILDING_TYPES,
  URL_GET_ALL_EDUCATIONS,
  URL_RELATIVE_TYPES,
  URL_GET_VULNERABILITY,
  URL_GET_CITIZEN_BUILDING_TYPES,
} from './constants'
import { client } from '../client'

export const DICTIONARY_URLS = {
  SECOND_STEP: {
    URL_GET_CITIZEN_BUILDING_TYPES,
    URL_GET_ALL_EDUCATIONS,
    URL_RELATIVE_TYPES,
    URL_GET_VULNERABILITY,
    URL_BUILDING_TYPES,
  },
}

export const useGetAllDictionaries = (url) => {
  const queryObjects = Object.entries(url).map(([key, url]) => ({
    queryKey: [key],
    queryFn: () => client.get(url).then((res) => res.data),
  }))

  const results = useQueries({ queries: queryObjects })

  const isLoading = results.some((query) => query.isLoading)
  const data = results.map((i) => i.data)
  return { data, isLoading }
}
