export const URL_GET_CLOTHE_TYPES = '/api/Dictionary/GetClothes'
export const URL_GET_SHOE_TYPES = '/api/Dictionary/GetShoes'
export const URL_GET_SUPPORT_INSTRUMENT_TYPES =
  '/api/Dictionary/GetSupportInstruments'
export const URL_GET_SWADDLER_TYPES = '/api/Dictionary/GetSwaddlers'
export const URL_SAVE_CITIZEN_APPLICATION = '/api/Application/CreateApplication'
export const URL_GET_VULNERABILITY = '/api/Dictionary/GetVulnerability'
export const URL_GET_SHOE_SIZE = '/api/Dictionary/GetShoeSize'
export const URL_GET_AGE_GROUP = '/api/Dictionary/GetAgeGroup'
export const URL_BUILDING_TYPES = '/api/Dictionary/GetBuildingTypes'
export const URL_ALL_REGIONS = '/api/Dictionary/GetAllRegions'
export const URL_COMMUNITY_BY_REGION_ID =
  '/api/Dictionary/GetCommunitiesByRegionId'
export const URL_GET_COUNTRIES_PREFIXES = '/api/Dictionary/GetCountriesPrefixes'
export const URL_SETTLEMENT_BY_COMMUNITY_ID =
  '/api/Dictionary/GetSettlementByCommunityId'
export const URL_POST_OFFICE_BY_REGION_ID =
  '/api/Dictionary/GetPostOfficesByRegionId'
export const URL_GET_ALL_EDUCATIONS = '/api/Dictionary/GetEducationTypes'
export const URL_RELATIVE_TYPES = '/api/Dictionary/GetRelativeTypes'
export const URL_GET_CITIZEN_BUILDING_TYPES = '/api/Dictionary/GetAccomodetions'
export const URL_GET_USER_DATA_FROM_BPR = '/api/Account/GetCitizenBprInfo'
export const URL_GET_INFO_WITHOUT_DOC =
  'api/Urrp/CheckAvvPersonsDataWithoutDocumentsAndGet'
export const URL_GET_CITIZEN_INFO_WITHOUT_DOC =
  '/api/Citizen/CheckAvvPersonsDataWithoutDocumentsAndGet'
export const URL_GET_CITIZEN_INFO = '/api/Citizen/GetCitizenInfo'
export const URL_GET_CHILD_SWADDLER_OPTIONS =
  '/api/Dictionary/GetCategoriesById?baseId=9'
export const URL_GET_ELDER_SWADDLER_OPTIONS =
  '/api/Dictionary/GetCategoriesById?baseId=8'
export const URL_GET_WOMEN_SWADDLER_OPTIONS =
  '/api/Dictionary/GetCategoriesById?baseId=11'
export const URL_GET_SHEET_OPTIONS =
  '/api/Dictionary/GetCategoriesById?baseId=10'
export const URL_GET_NEEDS_TYPES = '/api/Dictionary/GetNeedsTypes'
export const URL_GET_NEEDS_LOCATIONS = '/api/Map/GetNeedsLocations'
export const URL_GET_NEEDS_BY_COMMUNITY = '/api/Map/GetNeedsByCommunity'
