import { Col, Form, Row, Space, message } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import dayjs from 'dayjs'
import { FormSectionCard } from '../../../components/card/form-section-card'
import { Datepicker } from '../../../components/datepicker'
import { usePostMutate } from '../../../api/urrp/use-post-mutate'
import {
  URL_GET_CITIZEN_INFO,
  URL_GET_CITIZEN_INFO_WITHOUT_DOC,
} from '../../../api/urrp/constants'
import { Button } from '../../../components/button'
import { useWatch } from 'antd/es/form/Form'
import { ArmenianInput } from '../../../components/input/armenian-input'
import { useGetDictionary } from '../../../api/urrp/use-get-dictionary'

export const DIPersonDataForm = ({ disabled }) => {
  const form = useFormInstance()
  const isValidated = useWatch('isValidated', {
    preserve: true,
  })
  const ssn = useWatch(['personMainData', 'ssn'], {
    preserve: true,
  })
  useGetDictionary(
    URL_GET_CITIZEN_INFO,
    { ssn },
    {
      enabled: !!ssn,
      onSuccess: (data) => {
        form.setFieldsValue({
          personMainData: {
            registrationAddress: data.data?.registrationAddress,
          },
        })
      },
    },
  )
  const { mutate, isLoading } = usePostMutate(
    URL_GET_CITIZEN_INFO_WITHOUT_DOC,
    {
      onSuccess: (data) => {
        form.setFields([
          {
            name: ['personMainData', 'ssn'],
            value: data.data.ssn,
          },
          {
            name: 'isValidated',
            value: true,
          },
        ])
        message.success('Անձը նույնականացվել է։')
      },
      onError: () => message.error('Անձը չի նույնականացվել'),
    },
  )

  const handleClick = () => {
    if (isValidated) {
      form.resetFields([
        ['personMainData', 'firstName'],
        ['personMainData', 'lastName'],
        ['personMainData', 'patronymicName'],
        ['personMainData', 'birthdate'],
        ['personMainData', 'ssn'],
      ])
      form.setFields([
        {
          name: 'isValidated',
          value: null,
        },
      ])
    } else {
      form
        .validateFields([
          ['personMainData', 'firstName'],
          ['personMainData', 'lastName'],
          ['personMainData', 'patronymicName'],
          ['personMainData', 'birthdate'],
        ])
        .then((values) => {
          mutate({
            ...values.personMainData,
            birthdate: dayjs(values.personMainData.birthdate)
              .startOf('day')
              .format('YYYY-MM-DD'),
          })
        })
        .catch(() => message.error('Լրացրեք բոլոր անհրաժեշտ դաշտերը'))
    }
  }

  return (
    <FormSectionCard title={'Անձնական տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Անուն'}
            rules={[{ required: true }]}
            name={['personMainData', 'firstName']}
          >
            <ArmenianInput disabled={disabled || isValidated} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Ազգանուն'}
            rules={[{ required: true }]}
            name={['personMainData', 'lastName']}
          >
            <ArmenianInput disabled={disabled || isValidated} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Հայրանուն'}
            rules={[{ required: true }]}
            name={['personMainData', 'patronymicName']}
          >
            <ArmenianInput disabled={disabled || isValidated} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Ծննդյան ամսաթիվ'}
            rules={[{ required: true }]}
            name={['personMainData', 'birthdate']}
          >
            <Datepicker
              format="DD/MM/YYYY"
              style={{ borderRadius: 10, width: '100%' }}
              disabled={disabled || isValidated}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        hidden
        name={['personMainData', 'ssn']}
        rules={[{ required: true }]}
      />
      <Form.Item
        hidden
        name={'isValidated'}
        rules={[{ required: true }]}
        initialValue={false}
      />
      {!disabled && (
        <Space
          direction="horizontal"
          style={{ justifyContent: 'center', width: '100%' }}
        >
          <Button
            type="primary"
            danger={isValidated}
            size="large"
            shape="round"
            style={{ margin: '0 auto' }}
            onClick={handleClick}
            loading={isLoading}
          >
            {isValidated ? 'Չեղարկել' : 'Նույնականացնել'}
          </Button>
        </Space>
      )}
    </FormSectionCard>
  )
}
