import { forwardRef } from 'react'
import { Select } from '.'
import { FIELD_NAMES_DEFAULT } from './constants'
import { Skeleton } from 'antd'
import { useGetDictionary } from '../../api/urrp/use-get-dictionary'
import { URL_BUILDING_TYPES } from '../../api/urrp/constants'

export const BuildingTypesSelect = forwardRef((props, ref) => {
  const { data, isInitialLoading } = useGetDictionary(URL_BUILDING_TYPES)
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />
  }
  return (
    <Select
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      options={data}
      {...props}
      ref={ref}
    />
  )
})
