import './App.css'
import { ConfigProvider } from 'antd'
import { antTheme } from './helpers/ant-theme'
import { antForm } from './helpers/ant-form'
import hy from 'antd/locale/hy_AM'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  return (
    <ConfigProvider theme={antTheme} form={antForm} locale={hy}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </QueryClientProvider>
    </ConfigProvider>
  )
}

export default App
