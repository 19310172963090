import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useEffect } from 'react'
import { FormSectionCard } from '../../../components/card/form-section-card'
import { Col, Form, Row } from 'antd'
import { Input } from '../../../components/input'
import { PhoneNumberInput } from '../../../components/input/phonenum-code-input'

export const DIPersonContactForm = ({ disabled, email, phoneNumber }) => {
  const form = useFormInstance()
  useEffect(() => {
    if (disabled) {
      form.setFields([
        {
          name: ['personMainData', 'phoneNumber'],
          value: phoneNumber,
        },
        {
          name: ['personMainData', 'email'],
          value: email,
        },
      ])
    }
  }, [disabled, email, form, phoneNumber])

  return (
    <FormSectionCard title={'Կոնտակտային տվյալներ'}>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <PhoneNumberInput
          // name={['personMainData', 'phoneNumber']}
          // rules={[{ required: true }]}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            label={'Էլ․ փոստի հասցե'}
            rules={[
              {
                type: 'email',
                message: 'Սխալ Էլ․ փոստի ֆորմատ',
              },
              {
                required: true,
              },
            ]}
            name={['personMainData', 'email']}
          >
            <Input disabled={disabled} type="email" />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  )
}
