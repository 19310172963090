import { Form } from 'antd'
import { childFoodOptions, foodOptions } from '../constants'
import React from 'react'
import { Checkbox } from '../../../components/checkbox'

export const FoodCheckbox = ({ parentFormItem }) => {
  const checklist = Form.useWatch([...parentFormItem], {
    preserve: true,
  })

  const validateAtLeastOneChecked = (_, value) => {
    const hasChecked =
      value &&
      Object.values(value).some((v) => {
        if (typeof v === 'object') {
          return Object.values(v).some((subV) => subV === true)
        }
        return v === true
      })

    if (value?.babyFood) {
      const hasSelected = Object.values(value?.babyFood).some((v) => {
        return v === true
      })
      if (!hasSelected) {
        return Promise.reject(
          new Error('Խնդրում ենք ընտրել բացված դաշտերից առնվազն 1-ը'),
        )
      }
    }
    if (!hasChecked) {
      return Promise.reject(
        new Error('Խնդրում ենք ընտրել բացված դաշտերից առնվազն 1-ը'),
      )
    }
    return Promise.resolve()
  }
  return (
    <>
      <Form.Item
        name={parentFormItem}
        rules={[{ validator: validateAtLeastOneChecked }]}
        style={{ margin: 0 }}
      >
        <>
          {foodOptions.map((option) => (
            <div key={option.id}>
              <Form.Item
                name={[...parentFormItem, option.fieldName]}
                valuePropName="checked"
                style={{ margin: 0 }}
              >
                <Checkbox value={option.id} style={{ marginLeft: '20px' }}>
                  {option.label}
                </Checkbox>
              </Form.Item>
              {checklist?.babyFood &&
                option.id === 3 &&
                childFoodOptions.map((item) => (
                  <Form.Item
                    key={item.id}
                    name={[...parentFormItem, option.fieldName, item.fieldName]}
                    valuePropName="checked"
                    style={{ margin: 0 }}
                  >
                    <Checkbox value={item.id} style={{ marginLeft: '40px' }}>
                      {item.label}
                    </Checkbox>
                  </Form.Item>
                ))}
            </div>
          ))}
        </>
      </Form.Item>
    </>
  )
}
