import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../../helpers/constants'
import { ReactComponent as Gerb } from '../icon/coat.svg'
import styled from 'styled-components'

const MlsaLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    text-align: center;
    text-transform: uppercase;
  }
`

export const GerbSection = () => {
  const navigate = useNavigate()
  return (
    <MlsaLogo>
      <Gerb onClick={() => navigate(PATHS.ROOT)} />
    </MlsaLogo>
  )
}
