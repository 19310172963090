import styled from 'styled-components'
import { VerticalSpace } from '../../components/space/vertical-space'
import { useGetData } from '../../api/urrp/use-get-data'
import { URL_GET_NEEDS_BY_COMMUNITY } from '../../api/urrp/constants'
import { Collapse } from 'antd'
import { IconCircle } from './type-icon'

const { Panel } = Collapse

const PanelHeader = styled(Panel)`
  background-color: #f5f5f5 !important;
`

const CollapseTitle = styled.span`
  color: #555965;
  font-size: 12px;
`

const PanelContent = styled.div`
  font-size: 12px;
  line-height: 1.5;
  color: #333;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`

export const CardList = ({ communityId }) => {
  const { data } = useGetData(URL_GET_NEEDS_BY_COMMUNITY, {
    communityId: communityId,
  })
  return (
    <VerticalSpace>
      {Array.isArray(data) &&
        data.map((item) => (
          <Collapse accordion key={item.id} expandIcon={() => <IconCircle />}>
            <PanelHeader
              header={<CollapseTitle>{item.personName}</CollapseTitle>}
            >
              <PanelContent>
                <p>
                  <strong>
                    {item.isPerson === true
                      ? 'Իրականացնող անձ:'
                      : 'Իրականացնող կազմակերպություն:'}{' '}
                  </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.personName}
                  </span>
                </p>
                <p>
                  <strong>Ծրագրի անվանում: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.programName}
                  </span>
                </p>
                <p>
                  <strong>Ոլորտ: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.sphere}
                  </span>
                </p>
                <p>
                  <strong>Աջակցության տեսակ:</strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.supportType}
                  </span>
                </p>
                <p>
                  <strong>Մարզ: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.region}
                  </span>
                </p>
                <p>
                  <strong>Համայնք: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.community}
                  </span>
                </p>
                <p>
                  <strong>Թիրախային խումբ: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.targetGroup}
                  </span>
                </p>
                <p>
                  <strong>Բյուջե: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.budget}
                  </span>
                </p>
                <p>
                  <strong>Արժույթ: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.currency}
                  </span>
                </p>
                <p>
                  <strong>Շահառուների նախատեսված թիվ: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.beneficiaryNumber}
                  </span>
                </p>
                <p>
                  <strong style={{ fontWeight: '900' }}>Ծրագրի սկիզբ: </strong>
                  <span style={{ fontWeight: '900', color: '#666' }}>
                    {item.programStartDate}
                  </span>
                </p>
                <p>
                  <strong style={{ fontWeight: '900' }}>Ծրագրի ավարտ: </strong>
                  <span style={{ fontWeight: '900', color: '#666' }}>
                    {item.programEndDate}
                  </span>
                </p>
                <p>
                  <strong>Կոնտակտային անձի էլ. փոստ: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.email}
                  </span>
                </p>
                <p>
                  <strong>Կոնտակտային անձի հեռախոսահար: </strong>
                  <span style={{ fontWeight: '400', color: '#666' }}>
                    {item.phoneNumber}
                  </span>
                </p>
              </PanelContent>
            </PanelHeader>
          </Collapse>
        ))}
    </VerticalSpace>
  )
}
