import styled from 'styled-components'

const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 15px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: auto;

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    height: 140px;
    line-height: 10px;
  }
`

export const PageTitle = () => {
  return <Title>Առաջնային կարիքների հրատապ արձագանքման հարթակ</Title>
}
