import { URL_GET_NEEDS_TYPES } from '../../api/urrp/constants'
import { forwardRef } from 'react'
import { Select } from '.'
import { useGetDictionary } from '../../api/urrp/use-get-dictionary'
import { FIELD_NAMES_DEFAULT } from './constants'

export const NeedsTypesSelect = forwardRef((props, ref) => {
  const { data } = useGetDictionary(URL_GET_NEEDS_TYPES)
  return (
    <Select
      fieldNames={FIELD_NAMES_DEFAULT}
      style={{ width: '100%' }}
      options={data}
      {...props}
      ref={ref}
    />
  )
})
