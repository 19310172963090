import styled from 'styled-components'
import { screenSize } from '../../helpers/constants'
export const PrimaryText = styled.h1`
  font-weight: 700;
  font-size: 21px;
  line-height: 35px;
  color: #0a584f;
  text-align: center;
  text-transform: uppercase;
  margin: 70px 180px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${screenSize.md}) {
    font-size: 12px;
    height: 140px;
    margin: 30px 10px;
    line-height: 18px;
  }
`
