import { Skeleton } from 'antd'
import { forwardRef } from 'react'
import { Select } from '.'

import { FIELD_NAMES_POSTAL_CODE } from './constants'
import { useGetDictionary } from '../../api/urrp/use-get-dictionary'
import { URL_POST_OFFICE_BY_REGION_ID } from '../../api/urrp/constants'

export const PostIndexSelect = forwardRef(({ regionId, ...props }, ref) => {
  const { data, isInitialLoading } = useGetDictionary(
    URL_POST_OFFICE_BY_REGION_ID,
    { regionId: regionId },
    { enabled: !!regionId },
  )
  if (isInitialLoading) {
    return <Skeleton.Input active size="large" block />
  }

  return (
    <Select
      fieldNames={FIELD_NAMES_POSTAL_CODE}
      style={{ width: '100%' }}
      options={data}
      {...props}
      ref={ref}
    />
  )
})
