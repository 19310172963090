import { Typography } from 'antd'
import { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { COLORS, screenSize } from '../../helpers/constants'

const { Text: TextComponent } = Typography

export const textSizeMedia = css`
  @media (max-width: ${screenSize.xxl}) {
    font-size: 15px;
    line-height: 1.3;
  }
`

export const textStyles = css`
  font-weight: 400;
  ${textSizeMedia}
`

export const Text = styled(
  // eslint-disable-next-line no-unused-vars
  forwardRef(({ color, ...props }, ref) => (
    <TextComponent {...props} ref={ref} />
  )),
)`
  && {
    ${textStyles}
    color: ${(props) => props.color || COLORS.PRIMARY.GRAY_DARK};
  }
`

// eslint-disable-next-line no-unused-vars
export const SmallText = styled(({ color, ...props }) => <Text {...props} />)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.color || '#091024'};
  }
`

// eslint-disable-next-line no-unused-vars
export const SecondaryText = styled(({ color, ...props }) => (
  <Text type="secondary" {...props} />
))`
  && {
    font-size: 19px;
    line-height: 16px;
    text-align: center;
    color: ${(props) => props.color || '#456FAE'};
    @media (min-width: 992px) {
      text-align: center;
    }
    @media (max-width: 576px) {
      font-size: 16px;
      text-align: justify;
    }
  }
`
// eslint-disable-next-line no-unused-vars
export const SecondaryTitle = styled(({ color, ...props }) => (
  <Text type="secondary" {...props} />
))`
  && {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 55px;
    color: ${(props) => props.color || '#000'};
  }
`
