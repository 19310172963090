import { Select as SelectComponent } from 'antd'
import styled from 'styled-components'

export const Select = styled(SelectComponent)`
  .ant-select {
    height: auto;
  }
  && {
    .ant-select-selector {
      background: linear-gradient(
        91.78deg,
        rgba(255, 255, 255, 0.64) 6.81%,
        rgba(255, 255, 255, 0.16) 100%
      );
      height: auto;
    }

    .ant-select-selection-item {
      white-space: nowrap;
      width: 290px;
    }
  }
`
