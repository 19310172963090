import { Form } from 'antd'
import { FormSectionCard } from '../../../components/card/form-section-card'
import { VerticalSpace } from '../../../components/space/vertical-space'
import { Text } from '../../../components/typography/text'
import { needOptions, needs } from '../constants'
import { Checkbox } from '../../../components/checkbox'
import React from 'react'
import { TextArea } from '../../../components/input/text-area'

export const ThirdStep = () => {
  const checklist = Form.useWatch([needs], {
    preserve: true,
  })

  return (
    <FormSectionCard title={'Կարիքի տվյալներ'}>
      <VerticalSpace>
        <Text>
          Խնդրում ենք այս ցանկից ընտրել առավելագույնը 5 կարիք, որոնք
          ամենաառաջնայինն են
        </Text>
        <Form.Item name={needs}>
          {needOptions.map((option) => {
            return (
              <React.Fragment key={option.id}>
                <Form.Item
                  name={[needs, option.fieldName]}
                  valuePropName="checked"
                  style={{ margin: 0 }}
                >
                  <Checkbox value={option.id}>{option.label}</Checkbox>
                </Form.Item>
                {checklist?.[option.fieldName] &&
                  option?.subItem &&
                  React.cloneElement(option.subItem, {
                    parentFormItem: [needs, option.fieldName],
                  })}
                {checklist?.[option.fieldName] && option?.hasComment && (
                  <Form.Item
                    name={[needs, option.fieldName, 'other']}
                    rules={[{ required: true }]}
                    style={{ marginBottom: 12 }}
                  >
                    <TextArea />
                  </Form.Item>
                )}
              </React.Fragment>
            )
          })}
        </Form.Item>
      </VerticalSpace>
    </FormSectionCard>
  )
}
