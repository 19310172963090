import styled from 'styled-components'
import { ReactComponent as Accommodation } from './images/accommodation.svg'
import { ReactComponent as Shoes } from './images/shoes.svg'
import { ReactComponent as Hand } from './images/hand-map.svg'
import { ReactComponent as HygienicItems } from './images/hygienic-items.svg'
import { ReactComponent as SchoolBag } from './images/school-bag-3.svg'
import { ReactComponent as Mattress } from './images/mattress-1.svg'
import { ReactComponent as Speaker } from './images/speaker-1.svg'
import { ReactComponent as Medicine } from './images/medicine.svg'
import { useMemo } from 'react'

// Styled component for the circle
const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: #456fae;
  border-radius: 50%;
`

// React component
export const IconCircle = ({ type }) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'shoes':
        return <Shoes size={16} color="white" />
      case 'hand':
        return <Hand size={16} color="white" />
      case 'hygienic-items':
        return <HygienicItems size={16} color="white" />
      case 'school-bag':
        return <SchoolBag size={16} color="white" />
      case 'matress':
        return <Mattress size={16} color="white" />
      case 'speaker':
        return <Speaker size={16} color="white" />
      case 'medicine':
        return <Medicine size={16} color="white" />
      default:
        return <Accommodation size={16} color="white" />
    }
  }, [type])
  return <Circle>{icon}</Circle>
}
