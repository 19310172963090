import { PlusCircleOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Button } from '../../../../components/button'
import React, { useEffect, useRef } from 'react'
import { SwaddlerListItem } from './swaddler-list-item'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

const SwaddlerFormData = ({
  fields,
  add,
  remove,
  parentProp,
  itemId,
  option,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ManageFields fields={fields} add={add} />
      {fields.map((field) => (
        <React.Fragment key={field.name}>
          <SwaddlerListItem
            field={field}
            remove={remove}
            add={add}
            parentProp={parentProp}
            itemId={itemId}
            option={option}
          />
        </React.Fragment>
      ))}
      <Button
        type="primary"
        size="large"
        shape="round"
        onClick={() => add()}
        style={{ margin: '20px auto' }}
      >
        <PlusCircleOutlined />
        Ավելացնել
      </Button>
    </div>
  )
}

export const AddSwaddler = ({ parentProp, itemId, option }) => {
  return (
    <Form.List name={[...parentProp]}>
      {(fields, { add, remove }) => (
        <SwaddlerFormData
          fields={fields}
          add={add}
          remove={remove}
          parentProp={parentProp}
          itemId={itemId}
          option={option}
        />
      )}
    </Form.List>
  )
}
