import { Input as InputComponent } from 'antd'
import styled from 'styled-components'
import { screenSize } from '../../helpers/constants'

export const Input = styled((props) => (
  <InputComponent
    // onPaste={(e) => {
    //   e.preventDefault();
    //   return false;
    // }}
    // onCopy={(e) => {
    //   e.preventDefault();
    //   return false;
    // }}
    {...props}
  />
))`
  font-size: 18px;
  padding: 10px 10px;
  ::placeholder {
    @media (max-width: ${screenSize.xxl}) {
      font-size: 18px;
      line-height: 1.4;
    }
  }

  &.ant-input-disabled {
    color: black;
  }
`

export const FormInput = (props) => {
  return <Input {...props} />
}

export { TextArea } from './text-area'
