import { useState } from 'react'
import { URL_GET_SUPPORT_INSTRUMENT_TYPES } from '../../../../api/urrp/constants'
import { useGetDictionary } from '../../../../api/urrp/use-get-dictionary'
import { Form, Spin } from 'antd'
import { Checkbox } from '../../../../components/checkbox'
import { VerticalSpace } from '../../../../components/space/vertical-space'
import { ErrorText } from '../../../../components/typography/error-text'

export const SupportInstrumentList = ({ parentFormItem }) => {
  const { data, isLoading } = useGetDictionary(URL_GET_SUPPORT_INSTRUMENT_TYPES)
  const [errorMessage, setErrorMessage] = useState(false)

  const validateAtLeastOneSelected = (_, val) => {
    if (!val?.length) {
      setErrorMessage('Խնդրում ենք ընտրել բացված դաշտերից առնվազն 1-ը')
      return Promise.reject()
    }
    setErrorMessage('')
    return Promise.resolve()
  }

  return (
    <Spin spinning={isLoading}>
      <Form.Item
        name={[...parentFormItem, 'selections']}
        style={{ marginLeft: '20px' }}
        rules={[{ validator: validateAtLeastOneSelected }]}
      >
        <Checkbox.Group>
          <VerticalSpace size="small">
            {data.map((option) => {
              return (
                <Checkbox key={option.id} value={option.id}>
                  {option.name}
                </Checkbox>
              )
            })}
          </VerticalSpace>
        </Checkbox.Group>
      </Form.Item>
      {errorMessage && <ErrorText text={errorMessage} />}
    </Spin>
  )
}
