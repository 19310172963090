import { Card, Grid, Layout } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { GerbSection } from '../../components/form/layouts/gerb-logo'
import { VerticalSpace } from '../../components/space/vertical-space'
import Maps from './map'
// import { ReactComponent as GerbMobile } from './gerb-color.svg'
import { ReactComponent as Nork } from '../home/nork.svg'
import { Title } from '../home/index-new'

const { Footer, Header } = Layout
const { useBreakpoint } = Grid

const CustomCard = styled(Card)`
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 50px;
  width: calc(100vw - 100px); // Full width minus 2*50px margin
  height: calc(100vh - 100px); // Full height minus 2*50px margin
  max-height: 920px;
  margin: 50px;

  /* justify-content: center;
  align-items: center; */
  .ant-card-body {
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 100%;
    position: relative;
  }
`

const ImageSection = styled.div`
  flex: 1; // Takes equal space as content section
  overflow: hidden; // Prevents overflow of image
  width: 100%;
  height: 101%;
  position: relative;
`

const FullHeightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: inherit; // Ensures the image covers the full section
  display: block; // Removes default inline styling on images
  position: absolute;
  z-index: 0;
`

const ContentSection = styled.div`
  flex: 1; // Takes equal space as image section
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MapSection = styled.div`
  position: absolute;
  top: 15%;
  left: 5%;
  width: 90%;
  height: 70%;
  max-height: 70%;
  overflow: hidden;
`

const MobileHeader = styled(Header)`
  height: 68px;
  box-shadow: 4px 4px 6px 0px #00000026;
  display: flex;
  justify-content: start;
  padding: 5px;
  align-items: center;
  background-color: #042c68;
`

const ContentData = () => {
  return (
    <>
      <ContentSection />
      <MapSection>
        <Maps />
      </MapSection>
    </>
  )
}

const NewHomePage = () => {
  const screens = useBreakpoint()

  return (
    <div>
      {screens.lg ? (
        <CustomCard>
          <ImageSection>
            <div
              style={{
                position: 'absolute',
                top: '20px',
                left: '30px',
                zIndex: '1',
              }}
            >
              <GerbSection />
            </div>
            <FullHeightImage src="/images/bg.png" alt="full page image" />
            <Nork
              style={{
                position: 'absolute',
                bottom: '-30',
                left: '0',
                zIndex: '1',
              }}
            />
          </ImageSection>

          <ContentData />
        </CustomCard>
      ) : (
        <Layout>
          <MobileHeader>
            <GerbSection />
          </MobileHeader>
          <VerticalSpace>
            <Title />
            <ContentData />
          </VerticalSpace>
          <Footer style={{ display: 'flex', justifyContent: 'center' }}>
            <Nork />
          </Footer>
        </Layout>
      )}
    </div>
  )
}

export default NewHomePage
