import { Form, Radio, Spin } from 'antd'
import { FormSectionCard } from '../../../components/card/form-section-card'
import { VerticalSpace } from '../../../components/space/vertical-space'
import { APPLICATION_MODE, familyData } from '../constants'
import { useWatch } from 'antd/es/form/Form'
import { AddFamilyMember } from '../components/manage-family-member'
import { FamilyDetails } from '../components/family-details'

import {
  DICTIONARY_URLS,
  useGetAllDictionaries,
} from '../../../api/urrp/use-get-all-dictionaries'
import { PersonDetails } from '../components/person-details'
import { ResideMemberCheckbox } from '../components/reside-member-checkbox'
import { useMatches } from 'react-router-dom'
import { CurrentLocationForm } from '../components/current-location-form'

export const SecondStep = () => {
  const matches = useMatches()
  const match = matches.find((match) => match.handle)
  const type = match?.handle?.type
  const hasfamilyMember = useWatch([familyData, 'hasFamilyMembers'], {
    preserve: true,
  })
  const { data, isLoading } = useGetAllDictionaries(DICTIONARY_URLS.SECOND_STEP)

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard title={'Ընտանիքի տվյալներ'}>
        <VerticalSpace>
          <CurrentLocationForm buildingTypes={data[0]} />
          <Form.Item
            label="Ունե՞ք ընտանիքի անդամներ"
            name={[familyData, 'hasFamilyMembers']}
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </Form.Item>
          {hasfamilyMember && (
            <AddFamilyMember
              relativeTypes={data[2]}
              educationTypes={data[1]}
              vulnerability={data[3]}
            />
          )}
          {hasfamilyMember === true ? (
            <FamilyDetails />
          ) : hasfamilyMember === false ? (
            <PersonDetails vulnerability={data[3]} educationTypes={data[1]} />
          ) : (
            <></>
          )}
          <ResideMemberCheckbox isView={type === APPLICATION_MODE.VIEW} />
          {/* <FamilyDetails /> */}
        </VerticalSpace>
      </FormSectionCard>
    </Spin>
  )
}
