import { Layout, Space } from 'antd'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import { screenSize } from '../helpers/constants'

const OpenModalLayoutStyled = styled(Layout)`
  background: ${(p) => p.background};
  border-radius: 8px;
  box-shadow: 2px 2px 8px 5px rgba(0, 0, 0, 0.15);
  padding: 0 30px;
  height: 286px;
  align-items: center;
  h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 35px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 20px;
  }
  @media only screen and (max-width: ${screenSize.md}) {
    height: 150px;
    padding: 0 20px;
    h2 {
      font-size: 18px;
    }
    .ant-space-item {
      svg {
        width: 70px;
      }
    }
  }
  @media only screen and (max-width: ${screenSize.xs}) {
    h2 {
      font-size: 14px;
      margin-left: 0px;
    }
    .ant-space-item {
      svg {
        width: 50px;
      }
    }
  }
  // Add your custom styles here
`
const { Content } = OpenModalLayoutStyled
export const OpenModalLayoutType = ({
  ImageComp,
  children,
  showModal,
  ...props
}) => {
  return (
    <OpenModalLayoutStyled onClick={showModal} {...props}>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Space>
          {ImageComp}
          <Title level={2}>{children}</Title>
        </Space>
      </Content>
    </OpenModalLayoutStyled>
  )
}
