import React from 'react'
import { Input, Select, Form } from 'antd'
import { useGetDictionary } from '../../api/urrp/use-get-dictionary'
import { URL_GET_COUNTRIES_PREFIXES } from '../../api/urrp/constants'

export const PhoneNumberInput = (props) => {
  const { data: allCountryCodes, isInitialLoading } = useGetDictionary(
    URL_GET_COUNTRIES_PREFIXES,
  )

  return (
    <Form.Item label="Հեռախոսահամար" required={true}>
      <Input.Group compact>
        <Form.Item name="countryCode" noStyle rules={[{ required: true }]}>
          <Select
            style={{ width: '40%' }}
            loading={isInitialLoading}
            options={allCountryCodes?.map((item) => {
              return {
                label: `${item.phonePrefix} ${item.country}`,
                value: item.phonePrefix,
              }
            })}
            placeholder="Երկիր"
          />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          noStyle
          rules={[
            {
              required: true,
              pattern: /^[0-9]*$/,
              message: 'Սխալ հեռախոսահամարի ֆորմատ',
            },
          ]}
        >
          <Input
            {...props}
            style={{ width: '60%' }}
            placeholder="Հեռախոսահամար"
          />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  )
}
