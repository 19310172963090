import { FirstStep } from './steps/first-step'
import { SecondStep } from './steps/second-step'
import { ThirdStep } from './steps/third-step'
import { FoodCheckbox } from './components/food-checkbox'
import { ClothesForm } from './components/form-lists/clothes-form'
import { ShoesForm } from './components/form-lists/shoes-form'
import { SupportInstrumentList } from './components/form-lists/support-instrument-list'
import { SwaddlerForm } from './components/form-lists/swaddler-form'
import { CareList } from './components/form-lists/care-list'
import {
  URL_GET_CHILD_SWADDLER_OPTIONS,
  URL_GET_ELDER_SWADDLER_OPTIONS,
  URL_GET_SHEET_OPTIONS,
  URL_GET_WOMEN_SWADDLER_OPTIONS,
} from '../../api/urrp/constants'
export const personMainData = 'personMainData'
export const familyData = 'familyDatas'
export const personDetails = 'personDetails'
export const memberDetails = 'memberDetails'
export const needs = 'needs'

export const formItemRegAddress = {
  regionId: {
    name: [personMainData, 'registrationAddress', 'regionId'],
  },
  region: {
    name: [personMainData, 'registrationAddress', 'region'],
  },
  communityId: {
    name: [personMainData, 'registrationAddress', 'communityId'],
  },
  community: {
    name: [personMainData, 'registrationAddress', 'community'],
  },
  settlementId: {
    name: [personMainData, 'registrationAddress', 'settlementId'],
  },
  settlement: {
    name: [personMainData, 'registrationAddress', 'settlement'],
  },
  street: {
    name: [personMainData, 'registrationAddress', 'street'],
  },
  building: {
    name: [personMainData, 'registrationAddress', 'building'],
  },
  buildingType: {
    name: [personMainData, 'registrationAddress', 'buildingType'],
  },
  buildingTypeId: {
    name: [personMainData, 'registrationAddress', 'buildingTypeId'],
  },
  apartment: {
    name: [personMainData, 'registrationAddress', 'apartment'],
  },
  postIndex: {
    name: [personMainData, 'registrationAddress', 'postIndex'],
  },
}

export const formItemActualAddress = {
  regionId: {
    name: [personMainData, 'actualAddress', 'regionId'],
  },
  region: {
    name: [personMainData, 'actualAddress', 'region'],
  },
  communityId: {
    name: [personMainData, 'actualAddress', 'communityId'],
  },
  community: {
    name: [personMainData, 'actualAddress', 'community'],
  },
  settlementId: {
    name: [personMainData, 'actualAddress', 'settlementId'],
  },
  settlement: {
    name: [personMainData, 'actualAddress', 'settlement'],
  },
  street: {
    name: [personMainData, 'actualAddress', 'street'],
  },
  building: {
    name: [personMainData, 'actualAddress', 'building'],
  },
  buildingType: {
    name: [personMainData, 'actualAddress', 'buildingType'],
  },
  buildingTypeId: {
    name: [personMainData, 'actualAddress', 'buildingTypeId'],
  },
  apartment: {
    name: [personMainData, 'actualAddress', 'apartment'],
  },
  postIndex: {
    name: [personMainData, 'actualAddress', 'postIndex'],
  },
}

export const DIsteps = [
  {
    title: 'Անձնական տվյալներ',
    content: <FirstStep />,
  },
  {
    title: 'Սոցիալական տվյալներ',
    content: <SecondStep />,
  },
  {
    title: 'Կարիքի տվյալներ',
    content: <ThirdStep />,
  },
]

export const resideMemberOptions = [
  {
    label: 'Առանց ծնողի ուղեկցության երեխա է',
    value: 1,
  },
  {
    label: 'Հոգեկան առողջության խնդիրներ ունեցող անձ է',
    value: 2,
  },
  {
    label: 'Ոչ',
    value: 3,
  },
]

export const subItemStyle = {
  style: {
    marginLeft: '40px',
  },
}
export const disabiltyTypes = [
  {
    name: 'հենաշարժողական հաշմանդամություն',
    id: 1,
  },
  {
    name: 'մտավոր, հոգեկան, զգայական հաշմանդամություն',
    id: 2,
  },
  {
    name: 'տեսողական հաշմանդամություն',
    id: 3,
  },
  {
    name: 'քրոնիկական հիվանդություններով պայմանավորված հաշմանդամություն',
    id: 4,
  },
  {
    name: 'Այլ',
    id: 5,
  },
]
export const minorDepList = [
  'underSixMonths',
  'fromSixMonthToOneYear',
  'fromOneToThreeYears',
  'fromThreeToSixYears',
  'pupils',
]

export const disabledDepList = [
  'immovable',
  'ocular',
  'psychic',
  'hearing',
  'chronic',
  'disabledChildren',
]

export const swaddlerList = [
  {
    id: 9,
    label: 'Մանկական տակդիր',
    url: URL_GET_CHILD_SWADDLER_OPTIONS,
  },
  {
    id: 8,
    label: 'Մեծահասակի տակդիր',
    url: URL_GET_ELDER_SWADDLER_OPTIONS,
  },
  {
    id: 11,
    label: 'Կնոջ միջադիր',
    url: URL_GET_WOMEN_SWADDLER_OPTIONS,
  },
  {
    id: 10,
    label: 'Ջրակայուն սավան',
    url: URL_GET_SHEET_OPTIONS,
  },
]
export const needOptions = [
  {
    id: 1,
    label: 'Կացարան',
    fieldName: 'accommodation',
  },
  { id: 2, label: 'Սնունդ', fieldName: 'food', subItem: <FoodCheckbox /> },
  { id: 3, label: 'Հագուստ', fieldName: 'clothes', subItem: <ClothesForm /> },
  { id: 4, label: 'Կոշիկ', fieldName: 'shoes', subItem: <ShoesForm /> },
  {
    id: 5,
    label: 'Աջակցող միջոցներ (պրոթեզաօրթոպեդիկ պարագաներ)',
    fieldName: 'supportInstruments',
    subItem: <SupportInstrumentList />,
  },
  { id: 6, label: 'Հիգիենայի պարագաներ', fieldName: 'hygieneItems' },
  {
    id: 7,
    label: 'Տակդիր/միջադիր',
    fieldName: 'swaddlers',
    subItem: <SwaddlerForm />,
  },
  { id: 8, label: 'Մահճակալ (ներքնակ)', fieldName: 'bed' },
  { id: 9, label: 'Անկողնային պարագաներ', fieldName: 'bedClothes' },
  { id: 10, label: 'Կենցաղային պարագաներ', fieldName: 'householdSupplement' },
  { id: 11, label: 'Դեղորայք', fieldName: 'medicine' },
  { id: 12, label: 'Դպրոցական պարագաներ', fieldName: 'schoolSupplies' },
  { id: 13, label: 'Հոգեբանական աջակցություն', fieldName: 'psychicalSupport' },
  { id: 14, label: 'Խնամք', fieldName: 'care', subItem: <CareList /> },
  { id: 15, label: 'Այլ', fieldName: 'other', hasComment: true },
]

export const foodOptions = [
  {
    id: 1,
    label: 'Չոր (չեփված) սննդամթերք',
    fieldName: 'rawFood',
  },
  {
    id: 2,
    label: 'Պատրաստի եփած սնունդ',
    fieldName: 'readyFood',
  },
  {
    id: 3,
    label: 'Մանկական սնունդ',
    fieldName: 'babyFood',
  },
]

export const childFoodOptions = [
  {
    id: 1,
    label: 'Մինչև 6 ամսական երեխայի համար',
    fieldName: 'underSixMonths',
  },
  {
    id: 2,
    label: '6 ամսականից 1 տարեկան երեխայի համար',
    fieldName: 'fromSixMonthToOneYear',
  },
  {
    id: 3,
    label: '1 տարեկանից',
    fieldName: 'overOneYear',
  },
]

export const genderOptions = [
  {
    label: 'Արական',
    value: 'M',
  },
  {
    label: 'Իգական',
    value: 'F',
  },
]

export const careOptions = [
  {
    label: 'Տնային պայմաններում',
    fieldName: 'inplace',
    id: 1,
  },
  {
    label: 'Խնամքի կենտրոնում (հաստատությունում)',
    fieldName: 'careCenter',
    id: 2,
  },
]

export const APPLICATION_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  FILL: 'fill',
}
