import { Form, Grid } from 'antd'
import { useState } from 'react'
import { ActionButtons } from './components/action-buttons'
import { VerticalSpace } from '../../components/space/vertical-space'
import { RapidNeedSteps } from './components/stepper'
import { DIsteps } from './constants'
import { ContentWrapper } from '../../components/form/layouts/content-wrapper'

const { useBreakpoint } = Grid

export const RapidNeed = () => {
  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()
  const items = DIsteps.map((item) => ({ key: item.title, title: item.title }))
  const screens = useBreakpoint()

  const next = () => {
    setCurrent(current + 1)
    document.getElementById('rapid-need-page').scrollIntoView()
  }

  const prev = () => {
    setCurrent(current - 1)
    document.getElementById('rapid-need-page').scrollIntoView()
  }

  return (
    <ContentWrapper
      style={{
        margin: screens.lg ? '30px' : '0',
        padding: screens.lg ? '30px' : '5px',
        width: '100%',
      }}
    >
      <RapidNeedSteps current={current} items={items} />
      <Form
        name="need-form"
        form={form}
        layout="vertical"
        autoComplete="off"
        preserve={true}
      >
        <VerticalSpace>
          <div id="rapid-need-page">{DIsteps[current].content}</div>
          <ActionButtons next={next} prev={prev} step={current} />
        </VerticalSpace>
      </Form>
    </ContentWrapper>
  )
}
