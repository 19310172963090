import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import { PageTitle } from '../../typography/page-title'
import { GerbSection } from './gerb-logo'
import { screenSize } from '../../../helpers/constants'

const { Header: HeaderComponent } = Layout

const HeaderStyle = styled(HeaderComponent)`
  background-color: #0a584f;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 100px;
  @media only screen and (max-width: ${screenSize.md}) {
    padding: 0;
    height: auto;
  }
`

export const Header = () => {
  return (
    <HeaderStyle>
      <Row align={'middle'} justify={'center'}>
        <Col md={24} lg={1}>
          <GerbSection />
        </Col>
        <Col md={24} lg={22} style={{ textAlign: 'center' }}>
          <PageTitle />
        </Col>
        {/* <SsaSection /> */}
      </Row>
    </HeaderStyle>
  )
}
