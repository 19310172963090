import { InputNumber as InputComponent } from 'antd'
import styled from 'styled-components'
import { screenSize } from '../../helpers/constants'

export const InputNumber = styled((props) => (
  <InputComponent
    onPaste={(e) => {
      e.preventDefault()
      return false
    }}
    onCopy={(e) => {
      e.preventDefault()
      return false
    }}
    {...props}
  />
))`
  ::placeholder {
    @media (max-width: ${screenSize.xxl}) {
      font-size: 15px;
      line-height: 1.4;
    }
  }

  &:disabled {
    color: black;
  }
`
