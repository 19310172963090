import styled from 'styled-components'
import { screenSize } from '../../../helpers/constants'
import { GerbSection } from './gerb-logo'

const Wrapper = styled.div`
  display: flex;
  height: 80px;
  background-color: #456fae;
  padding: 0 74px;
  margin-top: auto;
  margin-bottom: auto;

  @media only screen and (max-width: ${screenSize.sm}) {
    height: 150px;
  }

  .sub-text {
    color: #ffffff;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-transform: uppercase;
  }
`

export const SubHeader = () => {
  return (
    <Wrapper>
      <GerbSection />
    </Wrapper>
  )
}
