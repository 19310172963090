import { Col, Form, Row } from 'antd'
import { FormSectionCard } from '../../../components/card/form-section-card'
import { VerticalSpace } from '../../../components/space/vertical-space'
import { Input } from '../../../components/input'
import { RegionsSelect } from '../../../components/select/regions-select'
import { CommunitySelect } from '../../../components/select/community-select'
import { SettlementSelect } from '../../../components/select/settlement-select'
import { BuildingTypesSelect } from '../../../components/select/building-types-select'
import { PostIndexSelect } from '../../../components/select/post-index-select'

export const DIPersonAddress = ({
  formItemAddress = {},
  viewMode = false,
  title = 'Հաշվառման հասցե',
  doAddressesMatch = false,
}) => {
  const form = Form.useFormInstance()
  const regionId = Form.useWatch(formItemAddress.regionId.name, {
    preserve: true,
  })
  const communityId = Form.useWatch(formItemAddress.communityId.name, {
    preserve: true,
  })

  const onRegionChange = () => {
    form.resetFields([
      formItemAddress.communityId.name,
      formItemAddress.settlementId.name,
      ...(formItemAddress.postIndex ? [formItemAddress.postIndex.name] : []),
    ])
  }

  const onCommunityChange = () => {
    form.resetFields([formItemAddress.settlementId.name])
  }

  return (
    <FormSectionCard title={title}>
      <VerticalSpace>
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Մարզ"
              name={
                viewMode
                  ? formItemAddress.region.name
                  : formItemAddress.regionId.name
              }
              rules={[{ required: true }]}
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <RegionsSelect
                  labelInValue
                  onChange={onRegionChange}
                  disabled={viewMode}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Համայնք"
              name={
                viewMode
                  ? formItemAddress.community.name
                  : formItemAddress.communityId.name
              }
              rules={[{ required: true }]}
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <CommunitySelect
                  labelInValue
                  onChange={onCommunityChange}
                  regionId={regionId?.value}
                  disabled={viewMode}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Բնակավայր"
              rules={[{ required: true }]}
              name={
                viewMode
                  ? formItemAddress.settlement.name
                  : formItemAddress.settlementId.name
              }
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <SettlementSelect
                  labelInValue
                  communityId={communityId?.value}
                  disabled={viewMode}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Փողոց"
              {...formItemAddress.street}
              rules={[{ required: true }]}
            >
              <Input disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Կառույցի տեսակ"
              rules={[{ required: true }]}
              name={
                viewMode
                  ? formItemAddress.buildingType.name
                  : formItemAddress.buildingTypeId.name
              }
            >
              {viewMode ? (
                <Input disabled={viewMode} />
              ) : (
                <BuildingTypesSelect labelInValue disabled={viewMode} />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Կառույցի համար"
              {...formItemAddress.building}
              rules={[{ required: true }]}
            >
              <Input disabled={viewMode} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Բնակարան" {...formItemAddress.apartment}>
              <Input disabled={viewMode} />
            </Form.Item>
          </Col>
          {/* {formItemAddress.postIndex && ( */}
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Փոստային դասիչ" {...formItemAddress.postIndex}>
              <PostIndexSelect
                regionId={doAddressesMatch ? regionId : regionId?.value}
              />
            </Form.Item>
          </Col>
          {/* )} */}
        </Row>
      </VerticalSpace>
    </FormSectionCard>
  )
}
