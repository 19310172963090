/* eslint-disable no-unused-vars */
import { createBrowserRouter } from 'react-router-dom'
import { ErrorPage } from './pages/error'
import { Application } from './pages/application/index'
import HomePage from './pages/home'
import { ApplicationLayout } from './components/form/layouts/application-layout'
import { PublicRoutes } from './components/routes/public-route'
import { PATHS } from './helpers/constants'
import { RapidNeedLayout } from './components/form/layouts/rapid-need-layout'
import { RapidNeed } from './pages/rapid-need'
import NewHomePage from './pages/home/index-new'
import Maps from './pages/maps'
import { LastHomePage } from './pages/home/index-last'

export const router = createBrowserRouter([
  {
    path: PATHS.ROOT,
    element: <LastHomePage />,
  },
  {
    path: PATHS.MAPS,
    element: <Maps />,
  },
  // {
  //   path: PATHS.ROOT,
  //   element: <HomePage />,
  //   children: [
  //     {
  //       element: <PublicRoutes />,
  //       children: [
  //         {
  //           element: <ApplicationLayout />,
  //           children: [
  //             {
  //               path: PATHS.ROOT,
  //               element: <Application />,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  //   errorElement: <ErrorPage />,
  // },
  {
    element: <RapidNeedLayout />,
    children: [
      {
        path: PATHS.RAPID_NEED,
        element: <RapidNeed />,
      },
    ],
  },
])
