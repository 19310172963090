import { MinusCircleOutlined } from '@ant-design/icons'
import { Col, Form, InputNumber, Row } from 'antd'

import { Select } from '../../../../components/select'
import { createOptionsFromArray } from '../../../../helpers/constants'
import { genderOptions } from '../../constants'
import { useGetDictionary } from '../../../../api/urrp/use-get-dictionary'
import { URL_GET_SHOE_SIZE } from '../../../../api/urrp/constants'
import { FormSectionCard } from '../../../../components/card/form-section-card'

export const ShoeListItem = ({ field, remove, itemId }) => {
  const { data: size } = useGetDictionary(URL_GET_SHOE_SIZE)

  return (
    <FormSectionCard
      title={
        field.name !== 0 && (
          <MinusCircleOutlined onClick={() => remove(field.name)} />
        )
      }
    >
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={8} style={{ width: '100%' }}>
          <Form.Item
            rules={[{ required: true }]}
            label={'Սեռը'}
            style={{ width: '100%' }}
            name={[field.name, 'gender']}
          >
            <Select options={genderOptions} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            hidden
            initialValue={itemId}
            name={[field.name, 'itemId']}
          />
        </Col>
        <Col xs={24} sm={24} md={8} style={{ width: '100%' }}>
          <Form.Item
            rules={[{ required: true }]}
            label={'Չափսը'}
            style={{ width: '100%' }}
            name={[field.name, 'size']}
          >
            <Select
              options={createOptionsFromArray(size || [])}
              style={{ width: '100%' }}
              labelInValue
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} style={{ width: '100%' }}>
          <Form.Item
            rules={[{ required: true }]}
            label={'Քանակը'}
            style={{ width: '100%' }}
            name={[field.name, 'quantity']}
          >
            <InputNumber style={{ width: '100%' }} min={1} max={9999999} />
          </Form.Item>
        </Col>
      </Row>
    </FormSectionCard>
  )
}
