import { Layout as LayoutComponent } from 'antd'
import { Outlet } from 'react-router-dom'

import styled from 'styled-components'

const Layout = styled(LayoutComponent)`
  background: #fdfdfd;
`

export const PublicRoutes = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
