import { useQuery } from '@tanstack/react-query'
import { client } from '../client'

export const usePostNoMutate = (url, body, options = { enabled: true }) => {
  const result = useQuery({
    queryKey: [url, body],
    queryFn: () => client.post(url, body),
    select: (data) => data.data,
    ...options,
  })
  const { data, isSuccess } = result
  return {
    ...result,
    data: isSuccess ? data.data : [],
  }
}
