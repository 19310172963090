import { Button, Card, Col, List, Modal, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import Weather from './weather.svg'
import { SecondaryText } from '../../components/typography/text'
import { VerticalSpace } from '../../components/space/vertical-space'
import UsaId from './images/usaid.svg'
import Coat from './images/coat.svg'
import Democracy from './images/democracy.svg'
import Nork from './images/nork.svg'
import { PATHS } from '../../helpers/constants'
import { useNavigate } from 'react-router-dom'

const CustomCard = styled(Card)`
  box-shadow:
    0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0 130px;

  .ant-card-body {
    padding: 10px;
  }

  @media (max-width: 576px) {
    width: calc(100vw - 40px);
    margin: 20px;
    padding: 5px;

    .ant-card-body {
      padding: 5px;
    }
  }
`

const Title = styled.span`
  width: 100%;
  font-size: 65px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 95px;
  padding: 5px 128px;

  @media (max-width: 1400px) {
    font-size: 45px;
    text-align: center;
    padding: 0;
    line-height: 45px;
  }

  @media (max-width: 768px) {
    font-size: 35px;
    text-align: center;
    padding: 0;
    line-height: 45px;
  }

  @media (max-width: 576px) {
    font-size: 30px;
    text-align: center;
    padding: 0;
    line-height: 25px;
  }
`

const buttonStyle = {
  width: '192px',
  height: '41px',
  borderRadius: '6px',
  background: '#005288',
  color: '#ffffff',
  fontSize: '16px',
  margin: '10px 0',
}

const modalButton = {
  wordBreak: 'break-word',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
  height: 'inherit',
  width: 'fit-content',
  borderRadius: '6px',
  background: '#005288',
  color: '#ffffff',
  fontSize: '16px',
  margin: '10px 0',
}

const iconStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const data = [
  {
    title: 'Ֆիզիկական անձ կամ անձանց խումբ կամ նախաձեռնություն',
    onClick: (modal) =>
      modal.confirm({
        title: 'ՈՒշադրություն',
        onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
        content:
          'Եթե ֆիզիկական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ  լրացրեք ՀՀ ԱՍՀՆ-ի  հետ համագործակցության հայտը: ',
        okText: 'Դիմել',
        cancelText: 'Փակել',
        closable: true,
        cancelButtonProps: { style: { display: 'none' } },
      }),
  },
  {
    title: 'Իրավաբանական անձ',
    onClick: (modal) =>
      modal.confirm({
        title: 'ՈՒշադրություն',
        onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
        content:
          'Եթե իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (ԱՍՀՆ) հետ ունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Եթե չունեք, ապա կարող եք լրացնել համագործակցության հայտ, որից հետո կկարողանաք ներկայացնել աջակցության հայտը: ',
        okText: 'Դիմել',
        cancelText: 'Փակել',
        closable: true,
        cancelButtonProps: { style: { display: 'none' } },
      }),
  },
  // {
  //   title: 'Օտարերկրյա անձ  կամ անձանց խումբ կամ նախաձեռնություն',
  //   onClick: (modal) =>
  //     modal.confirm({
  //       title: 'ՈՒշադրություն',
  //       onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
  //       content:
  //         'Եթե իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (ԱՍՀՆ) հետ ունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Եթե չունեք, ապա կարող եք լրացնել համագործակցության հայտ, որից հետո կկարողանաք ներկայացնել աջակցության հայտը: ',
  //       okText: 'Դիմել',
  //       cancelText: 'Փակել',
  //       closable: true,
  //       cancelButtonProps: { style: { display: 'none' } },
  //     }),
  // },
  // {
  //   title: 'Օտարերկրյա կազմակերպություն կամ միջազգային կազմակերպություն',
  //   onClick: (modal) =>
  //     modal.confirm({
  //       title: 'ՈՒշադրություն',
  //       onOk: () => (window.location.href = 'https://e-social.am/sign-in'),
  //       content:
  //         'Եթե իրավաբանական անձ եք, ապա աջակցության հայտ ներկանացնելուց առաջ համոզված եղեք, որ ՀՀ աշխատանքի և սոցիալական հարցերի նախարարության (ԱՍՀՆ) հետ ունեք համագործակցության գործող համաձայնագիր կամ հուշագիր։ Եթե չունեք, ապա կարող եք լրացնել համագործակցության հայտ, որից հետո կկարողանաք ներկայացնել աջակցության հայտը: ',
  //       okText: 'Դիմել',
  //       cancelText: 'Փակել',
  //       closable: true,
  //       cancelButtonProps: { style: { display: 'none' } },
  //     }),
  // },
]

export const LastHomePage = () => {
  const [modal, contextHolder] = Modal.useModal()
  const navigate = useNavigate()
  return (
    <CustomCard>
      {contextHolder}
      <VerticalSpace>
        <Row justify={'center'} align={'center'} gutter={[8, 8]}>
          <Col xs={12} sm={6} style={iconStyle}>
            <img src={UsaId} style={{ width: '60%' }} alt="" />
          </Col>
          <Col xs={12} sm={6} style={iconStyle}>
            <img src={Coat} style={{ width: '85%' }} alt="" />
          </Col>
          <Col xs={12} sm={6} style={iconStyle}>
            <img src={Democracy} style={{ width: '60%' }} alt="" />
          </Col>
          <Col xs={12} sm={6} style={iconStyle}>
            <img src={Nork} style={{ width: '60%' }} alt="" />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} md={22}>
            <img src={Weather} style={{ width: '100%' }} alt="*" />
          </Col>
          <Col
            xs={24}
            md={22}
            style={{ textAlign: 'center', background: '#005288 ', padding: 10 }}
          >
            <Title>հրատապ արձագանքման հարթակ</Title>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="center">
          <Col
            style={{
              textAlign: window.innerWidth >= 992 ? 'center' : 'justify',
            }}
          >
            <SecondaryText color={'#000000'}>
              Այս հարթակը նախատեսված է հրատապ արձագանք պահանջող իրավիճակներում
              առաջնային կարիքներին արձագանքելու համար։
            </SecondaryText>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="center">
          <Col
            xs={24}
            sm={12}
            xl={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              onClick={() => {
                modal.confirm({
                  title: 'ՈՒշադրություն',
                  onOk: () => navigate(PATHS.RAPID_NEED),
                  content:
                    'Հանկարծահաս ճգնաժամի հետևանքով վերջին 72 ժամվա ընթացքում ի հայտ եկած Ձեր  առաջնային կարիքները հաշվառելու համար կարող եք Դիմումների կառավարման միասնական հարթակում ստեղծել օգտահաշիվ կամ լրացնել դիմումն առանց օգտահաշիվ բացելու։ Դիմումում Ձեր նշած հեռախոսահամարով Ձեզ հետ կապ կհաստատի սոցիալական աշխատողը՝ անհրաժեշտ հարցերի պարզաբանման համար։',
                  okText: 'Դիմել',
                  cancelText: 'Փակել',
                  closable: true,
                  cancelButtonProps: { style: { display: 'none' } },
                })
              }}
              size="large"
              style={buttonStyle}
            >
              Կարիք ունեմ
            </Button>
          </Col>
          <Col
            xs={24}
            sm={12}
            xl={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              size="large"
              style={buttonStyle}
              onClick={() => {
                modal.confirm({
                  title: 'ՈՒշադրություն',
                  width: window.innerWidth >= 992 ? '45%' : '90%',
                  onOk: () => navigate(PATHS.RAPID_NEED),
                  content: screen.lg ? (
                    <List
                      grid={{
                        xs: 1,
                      }}
                      header="Աջակցության Ձեր առաջարկը հայտագրելու համար խնդրում ենք
                ստեղծել օգտահաշիվ եւ լրացնել հայտը: Խնդրում ենք նշված
                տարբերակներից ընտրել ձեզ համապատասխանողը․"
                      // dataSource={data}
                      itemLayout="vertical"
                    >
                      <VerticalSpace>
                        {data.map((item) => (
                          <List.Item.Meta
                            key={item.title}
                            style={{ textAlign: 'left' }}
                            description={
                              <Button
                                type="link"
                                onClick={() => item.onClick(modal)}
                                block
                              >
                                {item.title}
                              </Button>
                            }
                          />
                        ))}
                      </VerticalSpace>
                    </List>
                  ) : (
                    <VerticalSpace>
                      <span>
                        Աջակցության Ձեր առաջարկը հայտագրելու համար խնդրում ենք
                        ստեղծել օգտահաշիվ եւ լրացնել հայտը: Խնդրում ենք նշված
                        տարբերակներից ընտրել ձեզ համապատասխանողը․
                      </span>
                      <Row gutter={[16, 16]} justify="center">
                        {data.map((item) => (
                          <Col span={24} key={item.title}>
                            <Button
                              size="large"
                              style={modalButton}
                              type="link"
                              onClick={() => item.onClick(modal)}
                            >
                              {item.title}
                            </Button>
                          </Col>
                        ))}
                      </Row>
                    </VerticalSpace>
                  ),
                  okText: 'Դիմել',
                  cancelText: 'Փակել',
                  closable: true,
                  cancelButtonProps: { style: { display: 'none' } },
                  okButtonProps: { style: { display: 'none' } },
                })
              }}
            >
              Աջակցում եմ
            </Button>
          </Col>
        </Row>

        <Row justify="center">
          <Col
            xs={24}
            md={18}
            style={{
              textAlign: window.innerWidth >= 992 ? 'center' : 'justify',
            }}
          >
            <SecondaryText color={'#000000'}>
              Եթե վերջին 72 ժամվա ընթացքում տուժել եք այնպիսի հանկարծահաս
              ճգնաժամերից, ինչպիսիք են՝ բնական, կլիմայական, տեխնոլոգիական
              աղետները, ռազմական գործողությունները կամ կյանքին, առողջությանը
              վտանգ սպառնացող այլ իրավիճակներ, և ունեք հրատապ արձագանքի կարիք
              Ձեր առաջնային կարիքները բավարարելու համար, ապա կարող եք օգտվել այս
              հարթակից։
            </SecondaryText>
          </Col>
        </Row>

        <Row justify={'center'}>
          <Col xs={24} md={20}>
            <div style={{ textAlign: 'center', fontSize: 14 }}>
              Սույն հարթակը հնարավոր է դարձել Ամերիկայի ժողովրդի առատաձեռն
              աջակցությամբ՝ ԱՄՆ Միջազգային զարգացման գործակալության (ԱՄՆ ՄԶԳ)
              միջոցով: Բովանդակության համար պատասխանատվություն է կրում ՀՀ
              աշխատանքի և սոցիալական հարցերի նախարարությունը, և այն պարտադիր չէ,
              որ արտահայտի ԱՄՆ ՄԶԳ-ի կամ Միացյալ Նահանգների կառավարության
              տեսակետները:
            </div>
          </Col>
        </Row>
      </VerticalSpace>
    </CustomCard>
  )
}
