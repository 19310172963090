import { Col, Form, Row, Select } from 'antd'
import { familyData } from '../constants'
import { RegionsSelect } from '../../../components/select/regions-select'
import { CommunitySelect } from '../../../components/select/community-select'
import { SettlementSelect } from '../../../components/select/settlement-select'
import TextArea from 'antd/es/input/TextArea'
import { createOptionsFromArray } from '../../../helpers/constants'

export const CurrentLocationForm = ({ buildingTypes }) => {
  const form = Form.useFormInstance()

  const currentBuildingType = Form.useWatch([familyData, 'accommodationType'], {
    preserve: true,
  })

  const regionId = Form.useWatch([familyData, 'outdoorLocation', 'region'], {
    preserve: true,
  })
  const communityId = Form.useWatch(
    [familyData, 'outdoorLocation', 'community'],
    {
      preserve: true,
    },
  )

  const onRegionChange = () => {
    form.resetFields([
      [familyData, 'outdoorLocation', 'community'],
      [familyData, 'outdoorLocation', 'settlement'],
    ])
  }

  const onCommunityChange = () => {
    form.resetFields([[familyData, 'outdoorLocation', 'settlement']])
  }

  const onLocationChange = () => {
    form.resetFields([
      [familyData, 'outdoorLocation', 'region'],
      [familyData, 'outdoorLocation', 'community'],
      [familyData, 'outdoorLocation', 'settlement'],
      [familyData, 'outdoorLocation', 'notes'],
    ])
  }

  return (
    <>
      <Form.Item
        name={[familyData, 'accommodationType']}
        label={'Ներկա տեղակայման հասցեում կացարանի տեսակ'}
        rules={[{ required: true }]}
      >
        <Select
          options={createOptionsFromArray(buildingTypes || [])}
          style={{ width: '300px' }}
          labelInValue
          onChange={onLocationChange}
        />
      </Form.Item>

      <Row gutter={[12, 12]}>
        {currentBuildingType?.value === 12 && (
          <>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Մարզ"
                name={[familyData, 'outdoorLocation', 'region']}
                rules={[{ required: true }]}
              >
                <RegionsSelect labelInValue onChange={onRegionChange} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Համայնք"
                name={[familyData, 'outdoorLocation', 'community']}
                rules={[{ required: true }]}
              >
                <CommunitySelect
                  labelInValue
                  onChange={onCommunityChange}
                  regionId={regionId?.value}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Բնակավայր"
                rules={[{ required: true }]}
                name={[familyData, 'outdoorLocation', 'settlement']}
              >
                <SettlementSelect
                  labelInValue
                  communityId={communityId?.value}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Լրացուցիչ նշումներ"
                name={[familyData, 'outdoorLocation', 'notes']}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </>
  )
}
