import { useWatch } from 'antd/es/form/Form'
import { useState } from 'react'
import { Form } from 'antd'
import { Checkbox } from '../../../../components/checkbox'
import { AddSwaddler } from './manage-swaddler-form-list'
import { ErrorText } from '../../../../components/typography/error-text'
import { swaddlerList } from '../../constants'

export const SwaddlerForm = ({ parentFormItem }) => {
  const checkList = useWatch([...parentFormItem])
  const [errorMessage, setErrorMessage] = useState(false)

  const validateAtLeastOneSelected = () => {
    const isAnyChecked = Object.values(checkList).some(
      (val) => val?.id && val?.selections,
    )
    if (!isAnyChecked) {
      setErrorMessage('Խնդրում ենք ընտրել բացված դաշտերից առնվազն 1-ը')
      return Promise.reject()
    }
    setErrorMessage('')
    return Promise.resolve()
  }
  return (
    <>
      {swaddlerList?.map((option) => {
        return (
          <Form.Item
            key={option.id}
            name={[...parentFormItem, option.id]}
            style={{ margin: '0 0 0 20px' }}
            rules={[{ validator: validateAtLeastOneSelected }]}
          >
            <Form.Item
              name={[...parentFormItem, option.id, 'id']}
              valuePropName="checked"
              style={{ margin: 0 }}
            >
              <Checkbox value={option.id}>{option.label}</Checkbox>
            </Form.Item>
            <Form.Item hidden />

            {checkList?.[option.id]?.id && (
              <AddSwaddler
                parentProp={[...parentFormItem, option.id, 'selections']}
                itemId={option.id}
                option={option}
              />
            )}
          </Form.Item>
        )
      })}
      {errorMessage && <ErrorText text={errorMessage} />}
    </>
  )
}
