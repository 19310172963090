import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import { SubHeader } from './sub-header'

export const RapidNeedLayout = () => {
  return (
    <Layout style={{ background: 'transparent' }}>
      <SubHeader />
      <Outlet />
    </Layout>
  )
}
