import styled from 'styled-components'
import { Button as ButtonComponent } from 'antd'

export const Button = styled(ButtonComponent)`
  &.ant-btn-default {
  }

  &.ant-btn-default:disabled {
  }

  &.ant-btn-default:not(:disabled) {
  }
`
