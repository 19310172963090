import dayjs from 'dayjs'
import { DATE_FORMAT_YYYY_MM_DD } from '../../helpers/constants'

export const modifyFormListValues = (value = {}) => {
  return Object.values(value)
    .filter((item) => item.id)
    .flatMap((item) => item.selections)
}

export const generateAddressFields = (formItemAddress, data, actualAddress) => {
  return [
    {
      name: formItemAddress.regionId.name,
      value: data?.regionId || actualAddress?.regionId?.value,
    },
    {
      name: formItemAddress.region.name,
      value: data?.region || actualAddress?.regionId?.label,
    },
    {
      name: formItemAddress.communityId.name,
      value: data?.communityId || actualAddress?.communityId?.value,
    },
    {
      name: formItemAddress.community.name,
      value: data?.community || actualAddress?.communityId?.label,
    },
    {
      name: formItemAddress.settlementId.name,
      value: data?.settlementId || actualAddress?.settlementId?.value,
    },
    {
      name: formItemAddress.settlement.name,
      value: data?.settlement || actualAddress?.settlementId?.label,
    },
    {
      name: formItemAddress.street.name,
      value: data?.street || actualAddress?.street,
    },
    {
      name: formItemAddress.buildingType.name,
      value: data?.buildingType || actualAddress?.buildingTypeId?.label,
    },
    {
      name: formItemAddress.buildingTypeId.name,
      value: data?.buildingTypeId || actualAddress?.buildingTypeId?.value,
    },
    {
      name: formItemAddress.building.name,
      value: data?.building || actualAddress?.building,
    },
  ]
}
export const transformClothesData = (clothesArray) => {
  const result = {}

  clothesArray.forEach((item) => {
    const itemId = item.itemId

    item.ageGroup.key = item.ageGroup.value

    result[itemId] = {
      id: true,
      selections: [item],
    }
  })

  return result
}
export const transformSwaddlersData = (swaddlersArray) => {
  const result = {}

  swaddlersArray.forEach((item) => {
    const itemId = item.itemId
    item.size.key = item.size.value

    if (!result[itemId]) {
      result[itemId] = {
        id: true,
        selections: [],
      }
    }

    result[itemId].selections.push(item)
  })

  return result
}
export const transformShoesData = (shoesArray) => {
  const result = {}

  shoesArray.forEach((item) => {
    const itemId = item.itemId

    item.size.key = item.size.value

    result[itemId] = {
      id: true,
      selections: [item],
    }
  })

  return result
}
const isAllFieldsNull = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).every((value) => isAllFieldsNull(value))
  }
  return obj === null
}
export const processFoodObject = (formData) => {
  let food = {
    rawFood: formData.needs?.food?.rawFood || null,
    readyFood: formData.needs?.food?.readyFood || null,
    babyFood: {
      underSixMonths: formData.needs?.food?.babyFood?.underSixMonths || null,
      fromSixMonthToOneYear:
        formData.needs?.food?.babyFood?.fromSixMonthToOneYear || null,
      overOneYear: formData.needs?.food?.babyFood?.overOneYear || null,
    },
  }

  if (isAllFieldsNull(food)) {
    food = null
  }

  if (isAllFieldsNull(food?.babyFood)) {
    delete food.babyFood
  }

  return food
}
export const modifySaveValues = (formData) => {
  return {
    personMainData: {
      ...formData.personMainData,
      birthdate: dayjs(formData.personMainData.birthdate).format(
        DATE_FORMAT_YYYY_MM_DD,
      ),
      phoneNumber: formData?.countryCode + formData?.phoneNumber,
      registrationAddress: formData.personMainData.registrationAddress,
      actualAddress: {
        regionId: formData.personMainData.actualAddress?.regionId?.value,
        region: formData.personMainData.actualAddress?.regionId?.label,
        communityId: formData.personMainData.actualAddress?.communityId?.value,
        community: formData.personMainData.actualAddress?.communityId?.label,
        settlementId:
          formData.personMainData.actualAddress?.settlementId?.value,
        settlement: formData.personMainData.actualAddress?.settlementId?.label,
        buildingType:
          formData.personMainData.actualAddress?.buildingTypeId?.label,
        buildingTypeId:
          formData.personMainData.actualAddress?.buildingTypeId?.value,
        street: formData.personMainData?.actualAddress?.street || null,
        building: formData.personMainData?.actualAddress?.building || null,
        postIndex: formData.personMainData.actualAddress?.postIndex || null,
        apartment: formData.personMainData.actualAddress?.apartment || null,
      },
    },
    familyDatas: {
      ...formData.familyDatas,
      //! Family members are not validated in public application
      isValidated: false,
      accommodationType: {
        label: formData?.familyDatas?.accommodationType?.label || null,
        value: formData?.familyDatas?.accommodationType?.value || null,
      },
      outdoorLocation: {
        region: {
          label: formData?.familyDatas?.outdoorLocation?.region?.label || null,
          value: formData?.familyDatas?.outdoorLocation?.region?.value || null,
        },
        community: {
          label:
            formData?.familyDatas?.outdoorLocation?.community?.label || null,
          value:
            formData?.familyDatas?.outdoorLocation?.community?.value || null,
        },
        settlement: {
          label:
            formData?.familyDatas?.outdoorLocation?.settlement?.label || null,
          value:
            formData?.familyDatas?.outdoorLocation?.settlement?.value || null,
        },
        notes: formData?.familyDatas?.outdoorLocation?.notes || null,
      },
      familyMembers:
        formData.familyDatas?.familyMembers?.map((member) => {
          return {
            name: member.name,
            lastName: member.surname,
            workHistory: member.workHistory,
            physicalIssue: member.physicalIssue,
            mentalIssue: member.mentalIssue,
            patronymicName: '',
            vulnerability: {
              label: member.vulnerability?.label || null,
              value: member.vulnerability?.value || null,
            },
            birthdate: dayjs(member.birthdate)
              .startOf('day')
              .format('YYYY-MM-DD'),
            relationToMainMember: {
              label: member.relationToMainMember?.label || null,
              value: member.relationToMainMember?.value || null,
            },
            education: {
              label: member.education?.label || null,
              value: member.education?.value || null,
            },
            ssn: member.ssn,
          }
        }) || [],
      personDetails: {
        workHistory: null,
        physicalIssue: null,
        mentalIssue: null,
        comment: null,
        ...formData?.familyDatas?.personDetails,
        education: {
          label: formData?.familyDatas?.personDetails?.education.label || null,
          value: formData?.familyDatas?.personDetails?.education.value || null,
        },
        vulnerability: {
          label:
            formData?.familyDatas?.personDetails?.vulnerability.label || null,
          value:
            formData?.familyDatas?.personDetails?.vulnerability.value || null,
        },
        disabilty: {
          hasDisabilty:
            formData?.familyDatas?.personDetails?.disabilty?.hasDisabilty,
          disabiltyType: {
            label:
              formData?.familyDatas?.personDetails?.disabilty?.disabiltyType
                ?.label || null,
            value:
              formData?.familyDatas?.personDetails?.disabilty?.disabiltyType
                ?.value || null,
          },
          comment:
            formData?.familyDatas?.personDetails?.disabilty?.comment || null,
        },
        pregnancy: {
          isPregnant:
            formData?.familyDatas?.personDetails?.pregnancy?.isPregnant || null,
          pregnancyPeriod:
            formData?.familyDatas?.personDetails?.pregnancy?.pregnancyPeriod ||
            null,
        },
      },
    },
    needs: {
      bed: null,
      bedClothes: null,
      householdSupplement: null,
      medicine: null,
      psychicalSupport: null,
      schoolSupplies: null,
      hygieneItems: null,
      ...formData.needs,
      care: {
        inplace: formData.needs?.care?.inplace || null,
        careCenter: formData.needs?.care?.careCenter || null,
      },
      food: {
        rawFood: formData.needs?.food?.rawFood || null,
        readyFood: formData.needs?.food?.readyFood || null,
        babyFood: {
          underSixMonths:
            formData.needs?.food?.babyFood?.underSixMonths || null,
          fromSixMonthToOneYear:
            formData.needs?.food?.babyFood?.fromSixMonthToOneYear || null,
          overOneYear: formData.needs?.food?.babyFood?.overOneYear || null,
        },
      },
      clothes: modifyFormListValues(formData.needs?.clothes),
      shoes: modifyFormListValues(formData.needs?.shoes),
      swaddlers: modifyFormListValues(formData.needs?.swaddlers),
      supportInstruments: formData.needs?.supportInstruments?.selections || [],
      other: formData.needs?.other?.other || '',
    },
  }
}
export const modifyViewValues = (data) => {
  const formData = data.data.applicationViewResponse
  return {
    ...data.data,
    personMainData: {
      ...formData.personMainData,
      documentValidUntil: dayjs(formData.personMainData.documentValidUntil),
      birthdate: dayjs(formData.personMainData.birthdate),
      isRegAddressActual: formData.personMainData.isRegAddressActual,
      actualAddress: formData.personMainData.actualAddress,
      registrationAddress: formData.personMainData.registrationAddress,
    },
    familyDatas: {
      ...formData.familyDatas,
      accommodationType: {
        label: formData?.familyDatas?.accommodationType?.label || null,
        value: formData?.familyDatas?.accommodationType?.value || null,
      },
      outdoorLocation: {
        region: {
          label: formData?.familyDatas?.outdoorLocation?.region?.label || null,
          value: formData?.familyDatas?.outdoorLocation?.region?.value || null,
        },
        community: {
          label:
            formData?.familyDatas?.outdoorLocation?.community?.label || null,
          value:
            formData?.familyDatas?.outdoorLocation?.community?.value || null,
        },
        settlement: {
          label:
            formData?.familyDatas?.outdoorLocation?.settlement?.label || null,
          value:
            formData?.familyDatas?.outdoorLocation?.settlement?.value || null,
        },
        notes: formData?.familyDatas?.outdoorLocation?.notes || null,
      },
      familyMembers:
        formData.familyDatas?.familyMembers?.map((member) => {
          return {
            name: member.name,
            surname: member.lastName,
            workHistory: member.workHistory,
            physicalIssue: member.physicalIssue,
            mentalIssue: member.mentalIssue,
            patronymicName: member.patronymicName,
            vulnerability: {
              label: member.vulnerability?.label || null,
              value: member.vulnerability?.value || null,
            },
            birthdate: dayjs(member.birthdate),
            relationToMainMember: {
              label: member.relationToMainMember?.label || null,
              value: member.relationToMainMember?.value || null,
            },
            education: {
              label: member.education?.label || null,
              value: member.education?.value || null,
            },
            ssn: member.ssn,
          }
        }) || [],
      personDetails: {
        workHistory: null,
        physicalIssue: null,
        mentalIssue: null,
        comment: null,
        ...formData?.familyDatas?.personDetails,
        education: {
          label: formData?.familyDatas?.personDetails?.education.label || null,
          value: formData?.familyDatas?.personDetails?.education.value || null,
        },
        vulnerability: {
          label:
            formData?.familyDatas?.personDetails?.vulnerability.label || null,
          value:
            formData?.familyDatas?.personDetails?.vulnerability.value || null,
        },
        disabilty: {
          hasDisabilty:
            formData?.familyDatas?.personDetails?.disabilty?.hasDisabilty,
          disabiltyType: {
            label:
              formData?.familyDatas?.personDetails?.disabilty?.disabiltyType
                ?.label || null,
            value:
              formData?.familyDatas?.personDetails?.disabilty?.disabiltyType
                ?.value || null,
          },
          comment:
            formData?.familyDatas?.personDetails?.disabilty?.comment || null,
        },
        pregnancy: {
          isPregnant:
            formData?.familyDatas?.personDetails?.pregnancy?.isPregnant || null,
          pregnancyPeriod:
            formData?.familyDatas?.personDetails?.pregnancy?.pregnancyPeriod ||
            null,
        },
      },
    },
    needs: {
      ...formData.needs,

      food: processFoodObject(formData),
      care:
        formData.needs?.care?.inplace || formData.needs?.careCenter
          ? formData.needs?.care
          : null,
      clothes: formData.needs?.clothes?.length
        ? transformClothesData(formData.needs?.clothes)
        : null,
      shoes: formData.needs?.shoes?.length
        ? transformShoesData(formData.needs?.shoes)
        : null,
      swaddlers: formData.needs?.swaddlers?.length
        ? transformSwaddlersData(formData.needs?.swaddlers)
        : null,
      supportInstruments: formData?.needs?.supportInstruments?.length
        ? {
            selections: formData?.needs?.supportInstruments,
          }
        : null,
      other: formData.needs?.other
        ? {
            other: formData.needs.other,
          }
        : null,
    },
  }
}
