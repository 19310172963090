import { Col, Form, Row, Space, message, Button, Modal } from 'antd'

import { modifySaveValues } from '../utils'
import { URL_SAVE_CITIZEN_APPLICATION } from '../../../api/urrp/constants'
import { COLORS, errorMessage } from '../../../helpers/constants'
import { useNavigate } from 'react-router-dom'
import { useSaveApplication } from '../../../api/urrp/use-save-application'

export const ActionButtons = ({ next, prev, step }) => {
  const form = Form.useFormInstance()
  const navigate = useNavigate()
  const { mutate } = useSaveApplication(URL_SAVE_CITIZEN_APPLICATION, {
    onSuccess: (response) => {
      const modal = Modal.success({
        title: 'Շնորհակալություն',
        content: (
          <p>
            <p>
              <span>
                Ձեր հայտն ընդունված է: Առաջիկա հինգ աշխատանքային օրվա ընթացքում
                Ձեզ հետ կապ կհաստատի սոցիալական աշխատողը: Հայտի համարն է{` `}
              </span>
              <span
                style={{
                  color: COLORS.PRIMARY.BLUE,
                }}
              >
                {response.data.applicationNumber} :
              </span>
            </p>
          </p>
        ),
        footer: (
          <Row align="center" justify="center">
            <Col span={8}>
              <Space
                size="middle"
                align="center"
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '16px',
                }}
              >
                <Button
                  size="medium"
                  type="primary"
                  onClick={() => {
                    modal.destroy()
                    navigate('/')
                  }}
                >
                  Լավ
                </Button>
              </Space>
            </Col>
          </Row>
        ),
        closable: true,
        onCancel: () => navigate('/'),
      })
    },
    onError: errorMessage,
  })

  const onNext = () => {
    form
      .validateFields()
      .then((vals) => {
        if (step !== 2) {
          next()
        } else {
          const plainSelections = Object.values(vals?.needs || {}).filter(
            (item) => !!item,
          )
          const formData = form.getFieldsValue(true)
          if (plainSelections.length > 5 || plainSelections.length < 1) {
            message.error(
              'Խնդրում ենք ցանկից ընտրել մինչև 5 կարիք, որոնք առավել առաջնային են',
            )
            return
          } else {
            let dataToSave = modifySaveValues(formData)
            mutate(dataToSave)
          }
        }
      })
      .catch(() => {
        message.error('Կան սխալ լրացված դաշտեր')
      })
  }

  return (
    <Space style={{ justifyContent: 'center', display: 'flex' }}>
      {step > 0 && <Button onClick={() => prev()}>Նախորդ</Button>}
      {step < 2 && (
        <Button type="primary" onClick={onNext}>
          Հաջորդ
        </Button>
      )}
      {step === 2 && (
        <Button type="primary" htmlType="submit" onClick={onNext}>
          Հաստատել
        </Button>
      )}
    </Space>
  )
}
